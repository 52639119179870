import React from 'react'

import SvgBox from './SvgBox'

export default function CopyLink() {
  return (
    <SvgBox height="18px" width="17px">
      <svg
        width="17"
        height="18"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.21358 10.2868C7.89621 10.9693 8.822 11.3528 9.78733 11.3528C10.7527 11.3528 11.6785 10.9693 12.3611 10.2868L14.9344 7.71345C15.6062 7.02861 15.9805 6.10626 15.9759 5.14694C15.9713 4.18761 15.5882 3.2689 14.9098 2.59055C14.2315 1.91219 13.3127 1.52906 12.3534 1.52446C11.3941 1.51985 10.4717 1.89414 9.78692 2.56595L8.50025 3.85261M9.78692 7.71345C9.10433 7.03114 8.17871 6.64785 7.21358 6.64785C6.24846 6.64785 5.32284 7.03114 4.64025 7.71345L2.06608 10.2868C1.38348 10.9694 1 11.8952 1 12.8605C1 13.8259 1.38348 14.7517 2.06608 15.4343C2.74868 16.1169 3.67449 16.5004 4.63983 16.5004C5.60518 16.5004 6.53098 16.1169 7.21358 15.4343L8.50025 14.1476L9.78692 7.71345Z"
          stroke="#07223d"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgBox>
  )
}
