import React from 'react';

import logo from '../../images/logo.png';
import logo2 from '../../images/logo2.jpg';
import styles from './Logo.module.css';

function Logo({logoImg}) {
    return (
        <div className={styles.logoBox}>
            <img className={styles.logo} src={logoImg == '2' ? logo2 : logo} alt="logo"></img>
        </div>
    );
}

export default Logo;
