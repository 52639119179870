const colors = {
  darkBlue: '#07223d',
  lightBlue: '#07223d',
  accent: '#F1B750',
  brown: '#805928',
  grey: '#717171',
  lightGrey: '#bebebe',
  white: '#FFFFFF',
  red: '#E93D3D',
  dark: '#252020',
  text: '#07223d',
}

export { colors }
