// every file with React component must have imported React
import React, { useState, useEffect } from 'react'
// hooks for working with main store (Redux)
import { useSelector, useDispatch } from 'react-redux'
// hook for working with window.history
import { useHistory, Redirect } from 'react-router-dom'
// import PropTypes for describe component props
import PropTypes from 'prop-types'

// import node_modules and custom components
import Popover from '@material-ui/core/Popover'

import UserLogo from '../UserLogo'
import Button from '../Button'
import MiniLoading from '../MiniLoading'

// import components with SVG
import Licenses from '../svg/Licenses'
import Docs from '../svg/Docs'
import Connect from '../svg/Connect'
import Upgrade from '../svg/Upgrade'
import LogOut from '../svg/LogOut'
import Settings from '../svg/Settings'
import Unsubscribe from '../svg/Unsubscribe'

// import Styled Component
import StyledUserPlan from './StyledUserModal'

// import CSS Module
import styles from './UserModalBlock.module.css'
// import selectors and operations for working with main store (Redux)
import authSelectors from '../../redux/auth/auth-selectors'
import authOperations from '../../redux/auth/auth-operations'

// import methods for create request to API
import api from '../../services/api'

// import App routes
import routes from '../../routes'

function UserModalBlock({ onCloseClick, userLogo, upgradeUserPlan, upgradeLicensePlan }) {
  // connect selectors from main store like const variable
  const userName = useSelector(authSelectors.name)
  const userPlan = useSelector(authSelectors.plan)
  const userApi = useSelector(authSelectors.api)
  const userid = useSelector(authSelectors.id)

  // local component store with hook useState
  const [userLicenses, setUserLicenses] = useState(null)

  // download user licenses after mount this component
  useEffect(() => {
    api.auth
      .getLicenses()
      .then(response => setUserLicenses(+response.data.licenses))
      .catch(error => console.log('error: ', error))
  }, [])
  useEffect(() => {
    dispatch(authOperations.getPlans())
  }, [])

  // logic for Popover
  const [anchorEl, setAnchorEl] = useState(null)
  const copyApiToClipboard = event => {
    setAnchorEl(event.currentTarget)
    navigator.clipboard.writeText(userApi)
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  // create redirect to other route
  let history = useHistory()
  const goToSettings = () => {
    onCloseClick()
    history.push(routes.settingsAccount)
  }

  const openZapier = () => {
    onCloseClick()
    window.location.href = 'https://zapier.com/developer/public-invite/118107/df8642bba9ca130187e5ddc22e00e6a8/'
  }

  // create async event with change main store
  const dispatch = useDispatch()
  const logOut = () => {
    dispatch(authOperations.logOut())
  }

  // use onCloseClick function prop for closing modal
  const unsubscribe = () => {
    api.subscription
      .portal(userid)
      .then(response => {
        window.location.href = response.data.url
      })
      .catch(error => console.log('error: ', error))
    onCloseClick()
  }
  console.log(process.env)

  return (
    <>
      {/* styles from CSS Module connect like this 'className={styles.container}' */}
      <div className={styles.container}>
        <div className={styles.logoutHeader}></div>

        <div className={styles.header}>
          <UserLogo width="88px" border="6px solid #fff" userLogo={userLogo} />
          <div className={styles.userInfo}>
            <div className={styles.userName}>{userName}</div>
            <StyledUserPlan account={userPlan}>
              {userPlan.toLowerCase() === 'god mode' ? (
                userPlan
              ) : (
                <>
                  <span className={styles.userAccountTitle}>{userPlan}</span>
                  account
                </>
              )}
            </StyledUserPlan>
            <div className={styles.userNameLicenses}>
              {userPlan.toLowerCase() === 'god mode' ? (
                <>
                  <span className={styles.greenLicenses}>Unlimited</span>
                  licenses
                </>
              ) : (
                <>
                  {typeof userLicenses === 'number' ? (
                    <>
                      {userLicenses > 5 ? (
                        <span className={styles.greenLicenses}>
                          {userLicenses}
                        </span>
                      ) : (
                        <span className={styles.redLicenses}>
                          {userLicenses}
                        </span>
                      )}
                      licenses left
                    </>
                  ) : (
                    <MiniLoading />
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.logOutButton} onClick={logOut}>
            <LogOut />
          </div>
        </div>
        {process.env.REACT_APP_UPGRADE === '1' ? 
          !(userPlan.toLowerCase() === 'god mode') && (
              userPlan.toLowerCase() == "basic account"? '' :
                (
                  <div className={styles.button}
                    onClick={upgradeLicensePlan}>
                    <Licenses />{' '}
                    <span className={styles.buttonText}>Buy more licenses</span>
                  </div>
                )
          )
        : ''}
        <div className={styles.button} onClick={copyApiToClipboard}>
          <Docs />
          <span className={styles.buttonText}>API: {userApi}</span>
        </div>
        {/*
        // <div className={styles.button}>
        //   <Connect />
        //   <span className={styles.buttonText} onClick={openZapier}>Connect with Zapier</span>
        // </div>*/}
        {/* Unsubscribe */} 
        {userPlan.toLowerCase() == "basic account"? '' :
            (<div className={styles.button}
              onClick={unsubscribe}>
              <Unsubscribe />
              <span className={`${styles.buttonText} ${styles.unsubscribe}`}>
                Manage plan
              </span>
            </div>)
        }
        
        {process.env.REACT_APP_UPGRADE === '1' ? 
          (<div className={styles.upgradeButton}>
            <Button
              bgColor="darkBlue"
              icon={<Upgrade />}
              width="250px"
              onClick={upgradeUserPlan}
            >
              Upgrade
            </Button>
          </div>)
        : ''}

        <div className={styles.settingsButton} onClick={goToSettings}>
          <Button
            bgColor="white"
            icon={<Settings />}
            width="250px"
            onClick={goToSettings}
          >
            Settings
          </Button>
        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={styles.popoverText}>API copied</div>
      </Popover>
    </>
  )
}

// describe components props
UserModalBlock.propTypes = {
  userLogo: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  upgradeUserPlan: PropTypes.func.isRequired,
}

export default UserModalBlock
