import React from 'react';

import SvgBox from './SvgBox';

export default function Docs() {
  return (
    <SvgBox width="12px" height="15px">
      <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 0.5H2.625C2.55625 0.5 2.5 0.55625 2.5 0.625V1.5C2.5 1.56875 2.55625 1.625 2.625 1.625H10.375V12.375C10.375 12.4438 10.4312 12.5 10.5 12.5H11.375C11.4438 12.5 11.5 12.4438 11.5 12.375V1C11.5 0.723437 11.2766 0.5 11 0.5ZM9 2.5H1C0.723437 2.5 0.5 2.72344 0.5 3V11.2922C0.5 11.425 0.553125 11.5516 0.646875 11.6453L3.35469 14.3531C3.38906 14.3875 3.42813 14.4156 3.47031 14.4391V14.4688H3.53594C3.59062 14.4891 3.64844 14.5 3.70781 14.5H9C9.27656 14.5 9.5 14.2766 9.5 14V3C9.5 2.72344 9.27656 2.5 9 2.5ZM3.96875 13.5H3.96562L1.625 11.1594V11.1562H3.96875V13.5Z"
          fill="#07223d"
        />
      </svg>
    </SvgBox>
  );
}
