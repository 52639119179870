import React from 'react'

import SvgBox from './SvgBox'

export default function LogOut() {
  return (
    <SvgBox width="14px" height="15px">
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.19997 0.56665C0.91707 0.56665 0.645759 0.679031 0.44572 0.87907C0.245681 1.07911 0.133301 1.35042 0.133301 1.63332V13.3667C0.133301 13.6495 0.245681 13.9209 0.44572 14.1209C0.645759 14.3209 0.91707 14.4333 1.19997 14.4333H9.19997C9.34142 14.4333 9.47707 14.3771 9.57709 14.2771C9.67711 14.1771 9.7333 14.0414 9.7333 13.9C9.7333 13.7585 9.67711 13.6229 9.57709 13.5229C9.47707 13.4228 9.34142 13.3667 9.19997 13.3667H1.19997V1.63332H9.19997C9.34142 1.63332 9.47707 1.57713 9.57709 1.47711C9.67711 1.37709 9.7333 1.24143 9.7333 1.09998C9.7333 0.958535 9.67711 0.822879 9.57709 0.72286C9.47707 0.622841 9.34142 0.56665 9.19997 0.56665H1.19997ZM11.4442 4.72238C11.3441 4.62224 11.2083 4.56598 11.0666 4.56598C10.925 4.56598 10.7892 4.62224 10.689 4.72238C10.5889 4.82253 10.5326 4.95836 10.5326 5.09998C10.5326 5.24161 10.5889 5.37744 10.689 5.47758L12.1792 6.96665H4.9333C4.79185 6.96665 4.6562 7.02284 4.55618 7.12286C4.45616 7.22288 4.39997 7.35854 4.39997 7.49998C4.39997 7.64143 4.45616 7.77709 4.55618 7.87711C4.6562 7.97713 4.79185 8.03332 4.9333 8.03332H12.1792L10.689 9.52238C10.5889 9.62253 10.5326 9.75836 10.5326 9.89998C10.5326 10.0416 10.5889 10.1774 10.689 10.2776C10.7892 10.3777 10.925 10.434 11.0666 10.434C11.2083 10.434 11.3441 10.3777 11.4442 10.2776L13.8442 7.87758C13.8939 7.82804 13.9333 7.76919 13.9602 7.70439C13.9871 7.6396 14.0009 7.57014 14.0009 7.49998C14.0009 7.42983 13.9871 7.36037 13.9602 7.29557C13.9333 7.23078 13.8939 7.17193 13.8442 7.12238L11.4442 4.72238Z"
          fill="#07223d"
        />
      </svg>
    </SvgBox>
  )
}
